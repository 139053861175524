
import { HttpLink } from 'apollo-boost';

import { ApolloClient as Apollo } from 'apollo-boost'
//import { HttpLink } from 'apollo-boost'
import { InMemoryCache } from 'apollo-boost'
import { ApolloLink } from 'apollo-boost';
import { setContext } from 'apollo-link-context';
import { createUploadLink } from 'apollo-upload-client'
// import { SchemaLink } from 'apollo-link-schema';

import { ApolloClient as ApolloClientInit} from '@Corim/architecture-frontend'

const ApolloClient = ApolloClientInit(null, Apollo, HttpLink, InMemoryCache, ApolloLink, setContext, createUploadLink)

export default ApolloClient 