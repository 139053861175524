import React, { Component } from 'react';
import { faHeart } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import backgroundText from '../media/backgroundText.jpg'

export default (props) => {
  return (
    <div className='absolute vh-100'>
      <div className='w-100 fixed'
        style={{zIndex: -10, overflowY:'hidden', top: 0, width: '105%'}}>
        <svg width="130%" height="100vh">
          <defs>
            <pattern id="pattern" x="0" y="0" width="1000" height="200" patternUnits="userSpaceOnUse">
              <image href={backgroundText} alt='Dither Studio shaking branding text' xlinkHref={require('../media/backgroundText.jpg')} opacity='.5' x="0" y="0" width="1000" height="200"></image>
            </pattern>
          </defs>
          <rect width="100%" height="100vh" fill="url(#pattern)" />
          </svg>
      </div>
    </div>
  )
}
