import React from 'react';
import { Document, Page } from 'react-pdf'

import guide from '../media/2019 Brochure.pdf'
import bluegrass from '../media/2019 Bluegrass.pdf'
import logo from '../media/DitherLogoWithText.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { Dispatch, Textbox, MultiSelect, BaseComponent } from '@Corim/architecture-frontend'
import history from '../history'
import Menu from '../Menu'

import ditherAd from '../media/ditherad.png'

import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const brochures = {
  bluegrass,
  guide
}

// const scales = {
//   bluegrass:  
//   guide: 864
// }

export default (props) => {
  const height = window.outerHeight * .80
  const width = window.outerWidth * .97
  const ratio = props.ui && props.ui.windowSize && props.ui.windowSize.ratio
  const useHeight = width * 2 > height ? true : false
  const numPages = props.pdf && props.pdf.numPages ? props.pdf.numPages : 1
  const pageNumber = props.pdf && props.pdf.page ? props.pdf.page : 1
  let wScale = 1
  let hScale = 1
  if (props.brochure === 'bluegrass'){
    wScale = pageNumber === 1 || pageNumber === 3 ? width/702 : width/1404
    hScale = height/972
  }
  else {
    wScale = pageNumber === 1 || pageNumber === 21 ? width/432 : width/864 
    hScale = height/432
  }
  const scale = wScale > hScale ? hScale : wScale
  const brochure = brochures[props.brochure]

  const onLoadSuccess = (doc) => {
    Dispatch.mergeState('pdf', { numPages: doc.numPages })
  }

  return (
    <div className='w-100 h-100'>
      <div className='w-100 flex flex-column'>
        <div className='w-100 flex justify-between bg-white' style={{height: 80}}>
          {/* <div className='f1 pa2'><Menu /></div> */}
          <div onClick={e => history.push('/')} className='pl3 pr3 pb1 pt2 pointer' style={{width: 190}}> <img src={logo} /> </div>
          
        </div>
        <div className=' w-100 bg-black flex justify-around'>
          <div className='w-100 pa2 mw6 flex justify-between'>
            <FontAwesomeIcon className='f1 gray pulse pointer grow' icon={faAngleLeft} onClick={e => Dispatch.mergeState('pdf', { page: pageNumber > 1 ? pageNumber - 1 : 1 })}/>
            <FontAwesomeIcon className='f1 gray pulse pointer grow' icon={faAngleRight} onClick={e => Dispatch.mergeState('pdf', { page: pageNumber < numPages ? pageNumber + 1 : numPages })}/>
          </div>
        </div>
      </div>
    
      {/* // <div className='pb3 w-100 bg-black flex justify-around' style={{height: 130}}>
      //   <div className='w-100 pv2 mw5 flex justify-between'>
      //     <FontAwesomeIcon className='f1 gray pulse pointer grow' icon={faAngleLeft} onClick={e => Dispatch.mergeState('pdf', { page: pageNumber > 1 ? pageNumber - 1 : 1 })}/>
      //     <FontAwesomeIcon className='f1 gray pulse pointer grow' icon={faAngleRight} onClick={e => Dispatch.mergeState('pdf', { page: pageNumber < 21 ? pageNumber + 1 : 21 })}/>
      //   </div>
      // </div> */}
      <div className='pt3 pb3 w-100 flex justify-around items-center' >
        <Document file={brochure} onLoadSuccess={e => onLoadSuccess(e)}>
          <Page className='ba b--gray' scale={scale} pageNumber={pageNumber} />
        </Document>
      </div>
      {/* <div className='w-100' style={{backgroundColor: "#89C246"}}>
        <div className=''>
        <img src={ditherAd} />
        </div>
      </div> */}
    </div>
  )
}