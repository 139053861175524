import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons'
import { faBars } from '@fortawesome/free-solid-svg-icons'

import history from './history'

import { of } from 'rxjs'
import { delay, tap } from 'rxjs/operators'


class SimpleMenu extends React.Component {
  state = {
    anchorEl: null,
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = (name) => {
    this.setState({ anchorEl: null });
    //Dispatch.nextAction('scroll', name)
    //document.getElementById('home').scrollIntoView()
    //document.getElementById('contact').scrollIntoView()
  //  e.preventDefault()
  };
  handleClickItem = name => {
    history.push('/')
    of(1)
    .pipe(
      delay(500),
      tap(e => document.getElementById(name).scrollIntoView()),
      tap(e => this.setState({ anchorEl: null }))
    )
    .subscribe()
    // document.getElementById(name).scrollIntoView()
    // this.setState({ anchorEl: null });
  };

  render() {
    const { anchorEl } = this.state;

    return (
      <div>
        <Button
          aria-owns={anchorEl ? 'simple-menu' : null}
          aria-haspopup="true"
          onClick={this.handleClick}
        >
          <div className='f2 fl dark-gray'><FontAwesomeIcon icon={faBars} /></div>
        </Button>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          <MenuItem onClick={e => this.handleClickItem('about')}>Us.</MenuItem>
          <MenuItem onClick={e => this.handleClickItem('portfolio')}>Our Work.</MenuItem>
          <MenuItem onClick={e => this.handleClickItem('contact')}>Communicate.</MenuItem>
          {/* <MenuItem onClick={e => this.handleClose('contact')}>Contact</MenuItem> */}
        </Menu>
      </div>
    );
  }
}

export default SimpleMenu;
