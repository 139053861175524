import React, { Component } from 'react';
import history from '../history'
import _ from 'lodash'

const navigate = (props) => {
  if (props.selectable && !props.isVideo) {
    if (!_.isNil(props.url)) {
      window.location.href = props.url
    }
    else {
      history.push('/' + props.target)
    }
  }
  else {
    
  }
  const test = props.selectable && !props.isVideo ?
    history.push('/' + props.target)
    :
    null
}

export default (props) => {
  const style = {}
  const width = props.width ?
    style.width = props.width + '%'
    :
    null

  let target = null
  if (props.selectable && !props.isVideo) {
    if (!_.isNil(props.url)) {
      target = props.url
    }
    else {
      target = props.target
    }
  }

  return (
    <div className={'pb5  ' + (props.selectable ? '' : '')}
      >
      <div
        className=' ph2 '>
        <div className={'w-100 ' + (props.border ? 'b--light-gray ba br3' : '')}>
        {props.isVideo ?
          <video className='br3' width="100%" poster={props.poster} preload='metadata' controls>
            <source src={props.image} type="video/mp4"/>
          Your browser does not support the video tag.
          </video>
        :
        // <div className={ props.selectable ? 'center pointer grow ' : 'center' } onClick={() => navigate(props)} style={style}>
        target ? 
          <a style={style} href= {target} >
            <img
              className='w-100 br3'
              src={props.image}
              alt="logo" />
            </a>
            :
            <img
              className='w-100 br3'
              src={props.image}
              alt="logo" />
        }
      </div>
    </div>
      <div className='pl3 pr3 pt3 pb3 black '>
        <div
          className={'fw6 f4 f3-ns ' + props.align}
          onClick={()=>navigate(props)}>
          {props.headline}
        </div>

        <div className={' ' + props.align}>
          <h2 className='f5 fw4 fw3-ns blue mt1 mb1'>{props.tags}</h2>
        </div>
      </div>
    </div>
  )
}
