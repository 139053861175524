import React, { Component } from 'react';
import Item from './Item'
import ContactForm from '../shared/ContactForm/Contact'
import { Dispatch, Textbox, MultiSelect, BaseComponent } from '@Corim/architecture-frontend'
import 'tachyons'


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { faHeart } from '@fortawesome/free-solid-svg-icons'

import easttroyschoolad from '../media/easttroyschools.png'


import pta from '../media/pta.png'
import kinove from '../media/kinove.png'
import milker from '../media/milker.png'
import phdvideo from '../media/PHD R&D.mp4'
import brewfest from '../media/Brewfest.mp4'
import bluegrass from '../media/ET Bluegrass Raffle.mp4'
import phdposter from '../media/phdposter.png'
import lidm2 from '../media/lidm2.png'
import offices from '../media/Offices.png'
import slant from '../media/BlackSlant.svg'
import blackSlantBackward from '../media/BlackSlantBackward.svg'
import greenSlant from '../media/GreenSlant.svg'
import inklinkExterior from '../media/Inklink Exterior.jpg'
import engagementGraph from '../media/EngagementGraph.png'
import claimr from '../media/claimrScreenshot.png'
import teatsad from '../media/teatsad.jpg'
import cornbrat from '../media/corn&brat.png'
import etBrochureCover from '../media/etBrochureCover.png'
import tastyfork from '../media/tastyfork.png'
import EastTroyLightsGameVideo from '../media/East Troy Lights Game.mp4'
import EastTroyLightsGamePoster from '../media/EastTroyLightsGamePoster.png'

export default class App extends Component {
  constructor (props) {
    super(props)
    this.state = { readMore1: false, readMore2: false }
  }
  render() {
    const slantHeight = this.props.slantHeight
    return (
    <div className='w-100' > 
      {/* <div className='w-100 flex items-end' style={{height: '96vh'}}> </div> */}
        
      <div className='relative'>
        <div className='relative w-100' style={{top: 1, height: slantHeight, background: 'url(' + greenSlant + ')  no-repeat center center', backgroundSize: 'cover'}}></div>
        {/* <img className='relative w-100 db' src={greenSlant} alt="" /> */}
        <div
        className='relative pl3 pr3 f2 fw7 pt2 pb2 white flex justify-around'
        style={{backgroundColor: "#89C246"}}>
          {/* <div style={{height:'44px'}}></div> */}
              <div id='introExplanation' className='mw7 ph3'>
                  <div className='f3 f1-ns fw7 '>Dither Studio</div>
                  <h1 className='mt1 f5 f3-ns tl fw5 tj pb2-ns' >
                  We're professional button pushers working to help make our small part of the world a little more awesome.
                  </h1>
                </div>
        </div>
      </div>
      <div className='relative w-100 bg-black'>
        <div className='relative w-100' style={{top: -1, height: slantHeight, background: 'url(' + greenSlant + ')  no-repeat center center', backgroundSize: 'cover', transform: 'scale(-1,-1)'}}></div>
      </div>
      <div id='about' className='relative w-100 bg-black'>
        <div className='mw8 center pv5 ph4 pb4-ns'>
          
          <img className='w-100 br3 pb1 pt5-ns' src={offices} alt="Dither Studio image. Office space in restored building from 1854." />
          <div className='tr'><h2 className='f6 f5-ns white fw4 mb3'>Dither Studio in East Troy, WI</h2></div>
          <div className='mw6 center'>
            <div className='ph2-ns white pb2 pb5-ns pt3 tj'>
                    <div className=''><h2 className='f3 f1-ns fw7 mb3'>Dither Studio</h2></div>
                    <div className=' f5 f4-ns fw5 '>
                    Located on the historic East Troy square, Dither Studio resides in - what used to be - a Masonic hall (yep, the secretive society of Freemasons). Built in 1854,  
                    the building's third floor was supposedly home to this secretive group. And, with renovations in 2012 uncovering a large metal door hidden behind a plaster 
                    wall and a trap door in the
                    floor leading to a backroom staircase, we figure the rumors might be true.
                    <br/>
                    <br/>
                    Before the space became our new home, we spent a year in design and construction working out every detail: light placement, window frosting (to limit distractions), 
                    wall placement, etc. To finish off the project, we stained the wide plank white oak flooring
                    ourselves and added an artistic antiquing to the entire floor.
                    
                    <br/>
                    <br/>
                    We <FontAwesomeIcon className='f1 white pulse pointer grow' style={{color: "#89C246"}} icon={faHeart} /> East Troy!
                    </div>
                  </div>
            </div>
        </div>
      </div>
      {/* Case Study */}
      {/* <div className='relative w-100 bg-black' >
        <div className='relative w-100 h1 h4-ns' style={{background: 'url(' + blackSlantBackward + ')  no-repeat center center', backgroundSize: 'cover', transform: 'scale(1,-1)'}}></div>
        <div className='w-100 mw7 center ph3 white'>
          <div className='f2 f1-ns fw7 pb1 pt2'>Inklink Books</div>
          <div className='f4 f3-ns fw5 pb4'>East Troy, WI</div>
          <div className='f5 f4-ns fw6 pb1'>Basic Case Study:</div>
          <div className='f5 f4-ns fw4 pb4 tj'><span className='i'>Installing flame based exterior lighting: how data science changes high initial costs and continually reocurring costs into ROI. </span></div>
          <img className='w-100 br3 pb1' src={inklinkExterior} alt="Exterior image of Inklink Books bookstore" />
          <div className='relative f6 w-100 tr i pb3 pointer'>Exterior of Inklink Books with outdoor gas wall lanterns</div>
          <div className={this.state.readMore1 ? 'dn' : 'relative f4 w-100 tr blue pb2 pointer'} onClick={e => this.setState({ readMore1: !this.state.readMore1 })}>Read More</div>
          <div className={this.state.readMore1 ? 'pt1' : 'dn'}>
            <div className='f5 f4-ns fw6 pb2 pt1 '>Backstory:</div>
            <div> Inklink Books - located in East Troy, WI - needed help making a decision: 
            should outdoor gas wall lanterns (flame based lighting) be installed on the exterior of the building? <br/> <br/>Not only is the initial investment cost considerably higher for gas lanterns but also the reocurring monthly cost of operation.</div>
              <div className='f5 f4-ns fw6 pb2 pt4 '>Analysis:</div>
              <div className='pb3 tj'><span className='i'>1. Identify Quantifiable Metrics </span><br/><br/> 
                In order to attribute a value to a decision we need to identify the goals of the decision. 
                <br/><br/>
                <span className='i'>Why install gas lanterns instead of electric lanterns? </span> One possible answer is to gain word-of-mouth engagement.
                <br/><br/>
                <span className='i'>2. Attribute Values to Metrics</span>
                <br/><br/>
                Next we need to identify the value of a word-of-mouth engagement. We have no direct way to measure this value. But we can use analogous engagement values to create useful approximations. 
                In this case, Google Adwords provides a decent basis to approximate the average cost per engagement for our target audience: ~10$.
              </div>
              <img className='w-100 br3' src={engagementGraph} alt="A graph portraying investment returns" />
              <div className='f7 pb2 tj'>The graph illustrates the ROI of gas lanterns based on 
                the assumption that a word-of-mouth engagement is similar in value
                to a Google Adword engagement at ~$10 and that the initial upfront costs of the gas lanterns is an additional ~$2500 with a reoccuring monthly cost of ~$30/month.
              </div>
          </div>
        </div>
      </div> */}

      {/* Green Band */}
      <div className='relative bg-black'>
        <div className='relative w-100 ' style={{top: 1, height: slantHeight, background: 'url(' + greenSlant + ')  no-repeat center center', backgroundSize: 'cover'}}></div>
        <div
        className='relative pl3 pr3 f2 fw7 pt1 pb1 white'
        style={{backgroundColor: "#89C246"}}>
          <div className=' ph2 pv4 mw7 center f2 f1-ns tc'>
            Welcome to Dither.
          </div>
        </div>
        
      </div>
      <div className='relative'>
        <div className='relative w-100 ' style={{top: -1, height: slantHeight, background: 'url(' + greenSlant + ')  no-repeat center center', backgroundSize: 'cover', transform: 'scale(-1,-1)'}}></div>
      </div>
      {/* <div className='relative w-100 h4 h4-ns mb5' style={{background: 'url(' + blackSlantBackward + ')  no-repeat center center', backgroundSize: 'cover', transform: 'scale(1,-1)'}}></div> */}
      <div className='' style={{marginBottom: '40vh'}}> </div>
      <div className='relative w-100 mt5' style={{top: 1, height: slantHeight, background: 'url(' + slant + ')  no-repeat center center', backgroundSize: 'cover'}}></div>
      <div className='relative'>
        {/* <div id='portfolio' className='w-100 h1 h4-ns' style={{background: 'url(' + greenSlant + ')  no-repeat center center', backgroundSize: 'cover'}}></div> */}
        <div id='portfolio'
        className='relative pl3 pr3 f2 fw7 pt1 pb1 white bg-black'
        >
          <div className=' ph2 pv4 mw7 center f2 f1-ns tc'>
            (some of) Our work.
          </div>
        </div>
      </div>
      <div className='relative bg-white'>
      <div className='relative w-100' style={{top: -1, height: slantHeight, background: 'url(' + blackSlantBackward + ')  no-repeat center center', backgroundSize: 'cover', transform: 'scale(1,-1)'}}></div>
        {/* <div className='w-100 h4' style={{background: 'url(' + greenSlant + ')  no-repeat center center', backgroundSize: 'cover', transform: 'scale(-1,-1)'}}></div> */}
      </div>
      <div className='relative w-100 bg-white pt1' >
        <div className='w-100 mw7 center pt1'>
          {/* <div className='f2 f1-ns fw7 pb1'>Our Work.</div> */}
          {/* <div className='ph3'>
            <div className='f2 f1-ns fw7 pb1'>Kinove</div>
            <div className='f4 f3-ns fw5 pb4'>Southeast, WI</div>
            <div className='f5 f4-ns fw6 pb1'>Case Study:</div>
            <div className='f5 f4-ns fw4 pb4 tj'><span className='i'>"Love where you live": how polling people's desires leads to great branding </span></div>
            <img className='w-100 br3 pb1' src={kinove} alt="Case Study on using data in marketing" />
            <div className='relative f6 w-100 tr i pb3 pointer'>Posters made from images of Kinove rental spaces</div>
            <div className={this.state.readMore2 ? 'dn' : 'relative f4 w-100 tr blue pointer'} onClick={e => this.setState({ readMore2: !this.state.readMore2 })}>Read More</div>
            <div className={this.state.readMore2 ? 'pt1 ' : 'dn'}>
            <div className='f5 f4-ns fw6 pb2 pt1 '>Backstory:</div>
            <div>Kinove - located in southeast Wisconsin - made a business of renovating historic buildings in soon-to-be-up-and-coming towns. However, the price range on the rentals and quality of
              construction continually exceeded the area's ability to support the costs.
            </div>
              <div className='f5 f4-ns fw6 pb2 pt4 '>Analysis:</div>
              <div className='pb3 tj'><span className='i'>1. Identify the link between what people want and your company's mission. </span><br/><br/> 
                The rental spaces being constructed by Kinove were not connecting with the needs of the audience. Kinove prided itself on superb craftsmanship and architectually intriguing spaces
                and had no interest in constructing traditional apartment complexes and storefronts.<br/> <br/>
                Polling showed people overwhelming wanted to live in the rentals, but conceded the apartments were out of budget. A secondary polling which asked "How does the space make you feel?" generated responses
                including happiness, love, and tranquility. 
                This secondary polling created an obvious link between how Kinove saw its work and how its audience saw its work.
                <br/><br/>
              <span className='i'>2. Make it Explicit</span>
              <br/><br/>
              Armed with knowledge from polling, a company can proceed more boldly without fear of alienating their intended audience. Building on the ideas of love and tranquility, Kinove was not only able to
              create a slogan "love where you live" but also managed to assimilate this idea into its business culture.
              </div>
            </div>
          </div> */}

          <div className='pt5'> </div>
          <Item
            image={easttroyschoolad}
            align='tr'
            headline='East Troy School District Ad'
            tags='branding, print design, marketing'
            border={true}
            target='east-troy-brochure'
            selectable={false}
          />
          <Item
            image={etBrochureCover}
            align='tl'
            headline='East Troy Guide & Brochure'
            tags='pro bono, branding, print design, marketing, business strategy'
            //border={true}
            target='east-troy-brochure'
            selectable={true}
          />
          <Item
            image={tastyfork}
            align='tr'
            headline='Tasty Fork Website & Branding'
            tags='web app, ux design, branding, marketing'
            //border={true}
            target=''
            url='https://www.tastyfork.co'
            selectable={true}
          />
          <Item
            image={EastTroyLightsGameVideo}
            poster={EastTroyLightsGamePoster}
            align='tl'
            isVideo={true}
            headline='East Troy Lights Game'
            tags='software, design, integrations, marketing'
            target=''
            selectable={false}
          />
          {/* <Item
            image={claimr}
            align='tl'
            headline='Lab Software'
            tags='web app, ux design, website, branding, print design, business strategy'
            //border={true}
            target='lidm'
            selectable={false}
          /> */}
          <Item
            image={kinove}
            align='tr'
            headline='"Love Where You Live"'
            tags='branding, print design, photography, marketing, business strategy'
            //border={true}
            target='lidm'
            selectable={false}
          />
          <Item
            image={cornbrat}
            align='tl'
            headline='Corn & Brat Roast'
            tags='pro bono, branding, print design, marketing, business strategy'
            border={true}
            target='lidm'
            selectable={false}
          />
          <Item
            image={milker}
            align='tr'
            headline='The Dairy Decision Engine'
            tags='branding, website, marketing, print design'
            target='milker'
            selectable={false}
          />
          {/* <Item
            image={brewfest}
            //poster={phdposter}
            align='tr'
            isVideo={true}
            headline='East Troy Brewfest'
            tags='pro bono, video, photography, marketing'
            target='phd'
            selectable={false}
          /> */}
          {/* <Item
            image={inklinkExterior}
            align='tr'
            headline='Inklink Books'
            tags='photography, business strategy'
            target='inklink'
            selectable={false}
          /> */}
          <Item
            image={pta}
            align='tl'
            headline='Planes Trains & Automobiles'
            tags='pro bono, branding, event coordination, marketing, print design'
            target='pta'
            selectable={false}
          />
          <Item
            image={phdvideo}
            poster={phdposter}
            align='tr'
            isVideo={true}
            headline='PHD R&D'
            tags='video, photography'
            target='phd'
            selectable={false}
          />
          {/* <Item
            image={teatsad}
            align='tl'
            headline='"Milk Comes From Teats" - Campaign'
            tags='branding, website, marketing'
            //border={true}
            target='mepron'
            selectable={false}
          /> */}
          {/* <Item
            image={lidm2}
            align='tl'
            headline='Custom Software'
            tags='web app, ux design'
            //border={true}
            target='lidm'
            selectable={false}
          /> */}
          {/* <Item
            image={bluegrass}
            //poster={phdposter}
            align='tr'
            isVideo={true}
            headline='East Troy Bluegrass Raffle'
            tags='pro bono, video, photography, marketing'
            target='phd'
            selectable={false}
          /> */}
          {/* <Item
            image={offices}
            align='tl'
            headline='Our renovated offices in East Troy, WI'
            tags='interior design'
            selectable={false}
          /> */}
          {/* <Item
            image={etbluegrass}
            align='tl'
            headline='East Troy Bluegrass Festival'
            tags='branding, website, marketing, photography'
            target='bluegrass'
            selectable={true}
          />
          <Item
            image={inklink}
            align='tr'
            headline='Inklink Books'
            tags='photography'
            target='inklink'
            selectable={true}
          /> */}
          

          
          {/* <div
            className='pl4 pl5-ns f2 fw7 pt4 pb5 black bg-white'>

            <div className='pl3 f4 pv2'>
              <FontAwesomeIcon icon={faCheckCircle} /> Branding
            </div>
            <div className='pl3 f4 pv2'>
              <FontAwesomeIcon icon={faCheckCircle} /> Websites
            </div>
            <div className='pl3 f4 pv2'>
              <FontAwesomeIcon icon={faCheckCircle} /> Marketing
            </div>
            <div className='pl3 f4 pv2'>
              <FontAwesomeIcon icon={faCheckCircle} /> Photography
            </div>
            <div className='pl3 f4 pv2'>
              <FontAwesomeIcon icon={faCheckCircle} /> Videography
            </div>
            <div className='pl3 f4 pv2'>
              <FontAwesomeIcon icon={faCheckCircle} /> Event Coordination
            </div>
          </div> */}
          {/* <div className='f2 fw7 pt3 pb3 black'>
            <div className='tc f1 pv4'>
              Us
            </div>
            <div className='mt5 ml5 mr5 pr3 pl3 mw5 center'>
              <img className='w-100' src={avatarV}  alt="logo" />

            </div>
            <div className='bg-white pv3 tc'>V</div>
            <div className='mt5 ml5 mr5 pr3 pl3 mw5 center'>
              <img className='w-100' src={avatarJamie}  alt="logo" />

            </div>
            <div className='bg-white tc pv3'>Jamie</div>
            <div className='mt5 ml5 mr5 pr3 pl3 mw5 center'>
              <img className='w-100' src={avatarBen}  alt="logo" />

            </div>
            <div className='bg-white tc pv3'>Ben</div>
          </div> */}
        </div>
        </div>
        <div className='relative bg-white'>
          <div className='w-100 h4' style={{background: 'url(' + greenSlant + ')  no-repeat center center', backgroundSize: 'cover'}}></div>
          <div id='contact'
          className='relative pl3 pr3 f2 fw7 pt1 pb1 white'
          style={{backgroundColor: "#89C246"}}>
            <div className=' ph2 pv4 mw7 center f2 f1-ns tc'>
              Talk to us. We're here to help.
            </div>
          
          </div>
           
          <div className='w-100 h4' style={{background: 'url(' + greenSlant + ')  no-repeat center center', backgroundSize: 'cover', transform: 'scale(-1,-1)'}}></div>
         </div>
         

        <div className='relative w-100 bg-white flex flex-column items-center'>

 
          <div className='mw7 center'>
            <ContactForm {...this.props}/>
            {/* <div className='flex justify-center pt3 pb5 '>
              <div className="fb-share-button ph3" data-href="https://dither.studio" data-layout="button" data-size="large" data-mobile-iframe="true"><a target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fdither.studio%2F&amp;src=sdkpreparse" className="fb-xfbml-parse-ignore">Share</a></div>
              <a className="twitter-share-button ph3"
                href="https://twitter.com/intent/tweet?text=https://dither.studio"
                data-size="large">
              Tweet</a>
            </div> */}
          </div>
          <div className='h3 w-100 bg-black flex items-center justify-center'>
            <div className='white'>Copyright 2021 Dither LLC</div>
          </div>
          </div>
          
      </div>

  )
}
}
