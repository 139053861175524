import React, { Component } from 'react';
import Item from './Item'
import 'tachyons'
import { fromEvent } from 'rxjs';
import * as Rx from 'rxjs'
import { map, startWith, tap } from 'rxjs/operators';
import Menu from '../Menu'


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons'

//import BaseComponent from './BaseComponent'
import Portfolio from './Portfolio'
import About from './AboutUs'
import TextBackground from './TextBackground'
import loading from '../media/loading.svg'
import ditherBackground from '../media/Dither Background.mp4'
import logo from '../media/DitherLogoWithText.svg'
import ditherBackgroundImage from '../media/Dither Background Image.jpg'

//const ContactForm = ContactRx(Dispatch)

// const scrollDistance$ = fromEvent(document, 'scroll')
// const size$ = Dispatch.getState('ui.windowSize')

// const displayIntroExplanation$ = scrollDistance$
//   .pipe(
//     map(e => (window.innerHeight - (e.target.documentElement.scrollTop + e.target.body.scrollTop) > 0 )),
//     startWith( true)
//   )

// const store$ = Rx.combineLatest(
//     scrollDistance$.pipe(startWith(0)), 
//     displayIntroExplanation$,
//     size$,
//   )
//   .pipe(
//     map(([
//       scroll, 
//       displayIntroExplanation, 
//       size]) => ({scroll, displayIntroExplanation, size}))
//   )

const Home = (props) => {
  const height = props.ui && props.ui.windowSize ? props.ui.windowSize.height : 0
  const width = props.ui && props.ui.windowSize ? props.ui.windowSize.width : 0
  const displayed = props.displayIntroExplanation ? 'block' : 'none'
  const scroll = props.scroll
  const slantHeight = width/22
  const smallScreenTop = height < 630 ? height : 0
  const backgroundVideo = props.ui && props.ui.backgroundVideoReady ? props.ui.backgroundVideoReady : false
  return ( height ?
    <div>
      <TextBackground />
         {backgroundVideo ?
          <div className='fixed f1 pv2 ph2 pointer grow' style={{zIndex:1000}}>
            <Menu />
          </div>
          :
          null
         }
      <div className='fixed w-100 vh-100' style={{zIndex: -2}}>
        <video id='backgroundVideo' src={ditherBackground} poster={ditherBackgroundImage} className='o-20 db' style={{minWidth: '100%', minHeight: '100%', zIndex: -2, position: 'relative', left: '50%', transform: 'translateX(-50%) translateY(-50%)', top: '50%'}} autoPlay loop muted playsInline>
         
        </video>
      </div>
        <div className='relative w-100 black fixed pulse pointer grow f1 tc' style={{top: height*.90, zIndex: -1}}>
        { backgroundVideo ?
          <div className='o-30'>
            <FontAwesomeIcon icon={faAngleDown} />
          </div>
          :
          <img
          className='relative'
          style={{top: -70, width: 70}}
          //onClick={() => history.push('/home')}
          src={loading}
        />
        }
        </div>
      <div style={{zIndex: -1}}>
        <div className='w-100 flex justify-around items-center' style={{height: '95vh'}}>
          <div className='w-75 mw6 mb6'>
            <img
                  className='w-100'
                  //onClick={() => history.push('/home')}
                  src={logo}
                  alt="Dither Studio Logo | Marketing, Business Strategy, and Software Design"
                />
          </div>
        </div>
        {/* <div className={'fixed w-100 flex items-end '} style={{top: smallScreenTop, height: height}}>
          <div className='w-100 flex flex-column ' style={{display: displayed}}>
            <div className='relative w-100' style={{top: 1, height: slantHeight, background: 'url(' + slant + ')  no-repeat center center', backgroundSize: 'cover'}}></div>
              
            
            <div id='intro' className='relative w-100 bg-black white pb4'>
              <div className='relative mw7 f4 center ' >

                <div id='introExplanation' className='ph3'>
                  <div style={{height:'44px'}}></div>
                  <div className='f3 f1-ns fw7 '>Dither Studio</div>
                  <h1 className='mt1 f5 f3-ns tl fw5 tj pb2-ns' >
                  Bringing engineers and designers together to create bold solutions for branding, business strategy & software design.
                  </h1>
                </div>
              </div>
            </div>
            
            </div>
        </div> */}

      
        {/* <div className='w-100 f1 flex justify-center' style={{height: '22vh'}}>
          <div onClick={e=>document.getElementById('intro').scrollIntoView()} className='black fixed pulse pointer grow o-30'>
            <FontAwesomeIcon icon={faAngleDown} />
          </div>
          
        </div> */}
        
        </div>
        {/* <div className='w-100' style={{height: '70vh'}}></div> */}
        {
          backgroundVideo ? 
        <div>
          <Portfolio slantHeight={slantHeight} {...props}/>
        </div>
        :
          null
        }
    </div>
    :
    null
  )
}

export default Home
//export default (props) => <BaseComponent Component={Home} store={store$} {...props}/>