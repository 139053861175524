import { Dispatch, Textbox, MultiSelect, BaseComponent } from '@Corim/architecture-frontend'
import { fromEvent, combineLatest, interval, of } from 'rxjs'
import { map, startWith, takeWhile, finalize, filter, tap, delay, mergeMap } from 'rxjs/operators'

const scrollDistance$ = fromEvent(document, 'scroll')
const size$ = fromEvent(window, 'resize')
  .pipe(
    map(e => ({ height: e.target.innerHeight, width: e.target.innerWidth, ratio: e.target.devicePixelRatio})),
    startWith({ height: window.innerHeight, width: window.innerWidth, ratio: window.devicePixelRatio})
  )
  .subscribe(e => Dispatch.mergeState('ui', { windowSize: e }))

const displayIntroExplanation$ = scrollDistance$
  .pipe(
    map(e => (window.innerHeight - (e.target.documentElement.scrollTop + e.target.body.scrollTop) > 0 )),
    startWith( true)
  )


const store$ = combineLatest(
    scrollDistance$.pipe(startWith(0)), 
    displayIntroExplanation$,
    size$,
  )
  .pipe(
    map(([
      scroll, 
      displayIntroExplanation, 
      size]) => ({scrollDistance: scroll, displayIntroExplanation, windowSize: size}))
  )

  interval(100)
    .pipe(
      map(e => document.querySelector('#backgroundVideo')),
      filter(e => e !== null),
      map(e => e.readyState !== 4),
      takeWhile(readyState => readyState, true),
      mergeMap(readyState => readyState ? of(readyState) : of(readyState).pipe(delay(1000)))
    )
    .subscribe(e => Dispatch.mergeState('ui', { backgroundVideoReady: !e }))