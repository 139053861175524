import { fromEvent, from, of, EMPTY, merge, interval, timer } from 'rxjs';
import { Scheduler } from 'rxjs'
import { filter, map, mergeMap, scan, withLatestFrom, groupBy, pairwise, catchError, takeWhile, concatMap,
  startWith, tap, delay, combineLatest, switchMap, distinctUntilChanged, take, share, debounceTime, takeUntil, publishReplay, shareReplay} from 'rxjs/operators';
import _ from 'lodash'
// import { gql } from 'apollo-boost';
//import { Dispatch } from 'architecture'

const clock$ = interval(30, Scheduler.animationFrame)
  .pipe(
    map(e => {
      return Date.now() }),
    pairwise(),
    map(([prev, next]) => next - prev),
    share()
  )

const size$ = fromEvent(window, 'resize')
  .pipe(
    map(e => ({ height: e.target.innerHeight, width: e.target.innerWidth})),
    startWith({ height: window.innerHeight, width: window.innerWidth}),
  )

const mouseWind$ = fromEvent(document, 'mousemove')
  .pipe(
    map(e => ((e.clientX - (window.innerWidth/2))) / (window.innerWidth*1000)),
    map(e => e > .0003 ? .0003 : e < -.0003 ? -.0003 : e),
    shareReplay({refCount: true, bufferSize: 1}),
    startWith(0)
  )

mouseWind$
  .subscribe()

const A = .3
const ballHeight = 30

export default timer(0, 1000)
  .pipe(
    delay(Math.random()*3000),
    map(e => ({y: -20, x:-1*(window.innerWidth/2) + Math.random(100) * window.innerWidth*2})),
    map(e => ({ id: Math.random(100000), x: e.x, y: e.y })),
    mergeMap(f => { 
      let vel = 0
      let space = 0
      let windVel = 0
      let wind = f.x
      return clock$
        .pipe(
          withLatestFrom(size$, mouseWind$),
          map(([dT, size, mouseWind]) => {
            const dV = A * dT
            vel = vel + dV
            const dS = vel * dT + A / 2 * dT * dT
            space = space + dS
            const dMouseV = mouseWind * dT
            windVel = windVel + dMouseV
            wind = wind + windVel
            return { dT, dV, vel, dS, space: space/10000, height: size.height, x: wind }
          }),
          // Using DT over 1000 allows 1 last chance to remove snowflakes if massive lag (or phone off)
          takeWhile(e => (e.dT > 1000) || e.space < (e.height + 2000)),
          map(e => (acc) => {
            if (e.space < (e.height + 200)) {
              return {...acc, [f.id]: {...e, y: f.y} }
            }
            else {
              return _.omit(acc, f.id)
            }
          }),
        )
    }),
    scan((acc, next) => {
      return next(acc)
    }, {}),
    map(e => ({balls: {...e}})),
    //distinctUntilChanged((a, b) => _.isEqual(a, b)),
  )
