import React, { Component } from 'react';
import 'tachyons'
import _ from 'lodash'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons'

import { map, tap, take, startWith, delay, filter } from 'rxjs/operators'
import { combineLatest } from 'rxjs'
import { Dispatch, Textbox, MultiSelect, BaseComponent, SubmitButton } from '@Corim/architecture-frontend'

//const profile = require('../../media/VProfile.png')
import profile from '../../media/SarahProfile.png'
import loading from './media/loading.svg'
import './contactRx2'

export default (props) => {
  return (
    <div id="contact" className='w-100 bg-white black ph4 pb5 '>

      <img className='' src={profile} height='180' alt="Vanessa Profile Picture" />
       <div className='f2 fw6'>Sarah</div>
       <div>Director, New Business Development</div>
      
               <div className='mw6 w-100'>

          <Textbox
            className='w-100 mt4 pv3 ph3 ba br3 dark-gray b--moon-gray fw4 '
            id='contact.name'
            placeholder='Name'
            style={{ outline: 'none', borderStyle: 'solid' }}
            type='text'
            defaultValue={''}
          />
          <Textbox
            className='w-100 mt3 pv3 ph3 ba br3 dark-gray b--moon-gray fw4 '
            id='contact.email'
            placeholder='Email'
            style={{ outline: 'none', borderStyle: 'solid' }}
            type='text'
            defaultValue={''}
          />
          <Textbox
            className='w-100 mt3 pv4 ph3 ba br3 dark-gray b--moon-gray fw4 '
            id='contact.message'
            placeholder='Message'
            style={{ outline: 'none', borderStyle: 'solid' }}
            type='text'
            defaultValue={''}
          />
          <div className='pt3 w-100 flex justify-center justify-between-ns'>
            <div>
            </div>
            <SubmitButton id={'contactSubmit'} className='flex pt2 justify-between' 
              showSuccess={true}
              defaultChild={ props => (
                <div className='dib ph4 pv3 br2 bg-blue white pointer fw6' onClick={e => Dispatch.nextAction('contact.send')}>Submit</div>
              )}
              loadingChild={ props => (
                <div className='w-100 flex justify-between'>
                  <div></div>
                  <div className='w2'>
                    <img src={loading} />
                  </div>
                </div>
              )}
              successChild={ props => (
                <div className='dib ph4 pv3 br2 bg-blue white pointer fw6'>Success</div>
              )}
              errorChild={ props => (
                <div className='pl3 f5 fw7 red'>Error</div>
              )}
              loadingStream={
                Dispatch.getAction('Request.contact')
                }
              successStream={
                Dispatch.getAction('Response.contact')
                  .pipe(
                    delay(500),
                    filter(e => _.isNil(e.errors)),
                  )
                }
              errorStream={Dispatch.getAction('Response.contact')
              .pipe(
                filter(e => !_.isNil(e.errors)),
              )}
            />
          </div>
        </div>
      </div>
  )}



// import React, { Component } from 'react';
// import 'tachyons'
// import _ from 'lodash'

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faCheckCircle } from '@fortawesome/free-regular-svg-icons'

// import { map, tap, take, startWith } from 'rxjs/operators'
// import { combineLatest } from 'rxjs'
// import { Dispatch, Textbox, MultiSelect, BaseComponent } from '@Corim/architecture-frontend'

// //const profile = require('../../media/VProfile.png')
// import profile from '../../media/SarahProfile.png'
// import loadingIcon from './media/loading.svg'

// export default (props) => {
//   const form = props.contact && props.contact.form
//   const state = props.contact && props.contact.state
//   return (form ? 
//     <div id="contact" className='w-100 bg-white black ph4 pb5 '>
      
      
//       <img className='' src={profile} height='180' alt="Vanessa Profile Picture" />
//       <div className='f2 fw6'>Sarah</div>
//       <div>Director, New Business Development</div>
//       <label className="f5 fw6 b db mb1 mt5 fw3 tl">Name <span className="normal black-60"></span></label>
//       <input
//         className="input-reset ba br2 b--black-20 pa2 mt1 mb2 w-100"
//         type="text"
//         aria-describedby="name-desc"
//         onChange={e => Dispatch.mergeState('contact.form', { name: e.target.value } )}
//       />
//       <label className="f5 fw6 b db mb1 mt2 fw3 tl">Company <span className="normal black-60"></span></label>
//       <input
//         className="input-reset ba br2 b--black-20 pa2 mt1 mb2 w-100"
//         type="text"
//         aria-describedby="name-desc"
//         onChange={e => Dispatch.mergeState('contact.form', { company: e.target.value } )}
//       />
//       <label className="f5 fw6 b db mb1 mt2 fw3 tl">Email <span className="normal black-60"></span></label>
//       <input
//         className="input-reset ba br2 b--black-20 pa2 mt1 mb2 w-100"
//         type="text"
//         aria-describedby="name-desc"
//         onChange={e => Dispatch.mergeState('contact.form', { email: e.target.value } )}
//       />
//       <label className="f5 fw6 b db mb1 mt2 fw3 tl">Message <span className="normal black-60"></span></label>
//       <textarea
//         className="h4 input-reset ba br3 b--black-20 pa2 mt1 mb2 w-100"
//         type="text"
//         aria-describedby="name-desc"
//         onChange={e => Dispatch.mergeState('contact.form', { message: e.target.value } )}
//       />
//       {/* <label className="f4 b db mb1 mt2 fw3 tl">Contact <span className="normal black-60"></span></label> */}
//       {/* <div
//         className=' pt3 f5'>
//         <input
//           className=''
//           type="radio"
//           defaultChecked
//           name="contactPerson"
//           onChange={e => props.handleUpdateForm('contact', 'sales')}
//         /><span className='pl3'>Sales Member</span>
//         <div className='w-100 pv1'/>
//         <input
//           className=''
//           type="radio"
//           name="contactPerson"
//           onChange={e => props.handleUpdateForm('contact', 'claims')}
//         /><span className='pl3'>Claim Specialist</span>
//       </div> */}
//       {state === 'pending' ?
//       <PendingState />
//       :
//       <div
//         className='ba br2 ph3 pv3 mt5 mw5 tc center white'
//         onClick={e =>
//           Dispatch.nextAction('submitContactForm', "")
//         }
//         style={{backgroundColor: "#89C246"}}> { state}
//       </div>
//       }
//     </div>
//     :
//     null
//   )}

// const PendingState = (props) => {
//   return (
//     <div className='pv3 mt4 w3 center'>
//       <img className='w-100' src={loadingIcon}  alt="Logo" />
//     </div>
//   )
// }

// //Dispatch.mergeState('contact', {state: 'Submit'})
// // function handleSubmit(){
// //   Dispatch.nextAction('submitContactForm', "")
// // }

// // function handleUpdateForm(key, value) {
// //   Dispatch.getState('contact.form')
// //     .pipe(
// //       map(e => _.merge({}, e, {[key]: value})),
// //       take(1)
// //     )
// //     .subscribe(e => Dispatch.mergeState('contact.form', e))
// // }
// // Dispatch.getState('contact.form')
// // .subscribe(e => console.log("WTFFFFFFFFFFF"))
// // Dispatch.getState('contact.state')
// // .subscribe(e => console.log("noooooooooooooooo"))
// // const streams = combineLatest(Dispatch.getState('contact.form'), Dispatch.getState('contact.state'))
// //     .pipe(
// //       tap(e => console.log("asdfsdf")),
// // tap(e => console.log(e)),
// //       map(([form, submitState]) => 
// //         ({
// //           submitState,
// //           form,
// //           handleUpdateForm: handleUpdateForm,
// //           handleSubmit: handleSubmit
// //         })
// //       ),
// //       tap(e => console.log("omg")),
// // tap(e => console.log(e)),
// //     )

// //export default (props) => <BaseComponent Component={Contact} store={streams} {...props}/>